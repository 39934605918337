window.tables = {
    initResponsiveTable: function (targetId, containerId, dotnetRef, args) {
        const selector = `#${targetId} table.quickgrid`;
        const ref = document.querySelector(selector);
        if (ref) {

            args.method = (state) => {
                dotnetRef.invokeMethodAsync('InvokeOnGridColumnHidden', state.current);
            };

            bPodtable(ref, args);
        }

        addEventListener("resize", (event) => {
            window.tables.adjustGridSize(targetId, containerId);
        });
    },

    setLoadingState: function (targetId, loading) {
        const el = document.getElementById(targetId);
        if (el) {
            const childNodes = el.getElementsByTagName('*');

            for (let node of childNodes) {

                // if we're setting loading, and the node is currently disabled, we need to track it so we can keep it disabled when isloading is set to false
                // track it via a custom attribute, as elements won't always have an id or name we can investigate
                if(loading && node.disabled) {
                    node.setAttribute("data-grid-disabled", 'true');
                }

                // if setting loading to false, check if the node was disabled prior to loading, and if so, make sure it stays disabled
                else if(!loading && node.hasAttribute("data-grid-disabled"))
                {
                    node.removeAttribute("data-grid-disabled");
                    continue;
                }

                node.disabled = loading;
            }
        }
    },

    onAfterRender: function (targetId, containerId) {
        this.adjustGridSize(targetId, containerId);
    },

    adjustGridSize(targetId, containerId) {
        const gridTable = document.querySelector(`#${targetId} table.quickgrid`);
        const mainContent = document.querySelector('.main-content');

        const gridWrapper = document.getElementById(`${targetId}_dataGrid_wrapper`);
        if (gridWrapper) {
            requestAnimationFrame(function () {
                const container = containerId? document.getElementById(containerId) : gridWrapper.parentElement;
                const tableBody = gridTable.querySelector("tbody");

                // get bounding rectangles for elements on the page that factor into sizing of the grid wrapper element.
                // note, getBoundingClientRect includes the padding of the element                
                const mainContentBounds = mainContent.getBoundingClientRect();
                const wrapperPositionBounds = gridWrapper.getBoundingClientRect();
                const containerPositionBounds = container.getBoundingClientRect();
                const tableBodyBounds = tableBody.getBoundingClientRect();

                // don't resize if the wrapper and the table body are both within the bounds of the main content area
                if (wrapperPositionBounds.bottom <= mainContentBounds.bottom
                    && tableBodyBounds.bottom <= wrapperPositionBounds.bottom
                    && !tableBodyBounds.bottom <= mainContentBounds.bottom
                ) {
                    // remove the min height styles
                    if (gridWrapper.classList.contains('container-overflow')) {
                        gridWrapper.classList.remove('container-overflow');
                    }

                    return;
                }

                // add the min height styles to ensure the grid doesn't get to small if the main content area is significantly smaller than the grid
                if (!gridWrapper.classList.contains('container-overflow')) {
                    gridWrapper.classList.add('container-overflow');
                }

                // set the height of the wrapper if it's bottom position is either:
                //  - outside the bounds of the main content area
                //  - or, the table body bounds (has more rows than fit in the bounds) is outside the bounds of the main content area
                //  - or, the table body bounds (has more rows than fit in the bounds) is outside the bounds of the wrapper
                let newHeight = mainContentBounds.bottom - wrapperPositionBounds.top;

                // determine offset of the container from the bottom of the wrapper, and modify the new height accordingly
                const containerOffset = wrapperPositionBounds.bottom - containerPositionBounds.bottom;

                // get the height of the main content area, without padding
                const mainContentPaddingBottom = parseInt(getComputedStyle(mainContent, null).getPropertyValue('padding-bottom'));
                newHeight += containerOffset;
                newHeight -= mainContentPaddingBottom;

                gridWrapper.style.height = `${newHeight}px`;
            });
        }
    }
}