const ToastStyles = {
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning'
};

window.app = {
    dialogs: {
        showToast: function (args) {

            let message = null;
            let title = null;
            let style = 'default';

            if (typeof args === 'string' || args instanceof String) {
                message = args.toString();
            } else if (typeof args === 'object' &&
                !Array.isArray(args) &&
                args !== null
            ) {
                if (args.message)
                    message = args.message;

                if (args.title)
                    title = args.title;

                if (args.style)
                    style = args.style;

            } else {
                throw 'arguments for showToast are invalid. must be a string or object containing a message attribute'
            }

            if (message == null) {
                throw 'message must be defined'
            }

            const template = document.createElement("template");

            template.innerHTML = `
            <div class="toast shadow d-flex justify-content-between" role="alert" data-bs-delay="3000">
                <div class="toast-body">
                    <i class="fas fa-check-circle me-1"></i>
                    <strong class="toast-title"></strong>
                    <span class="toast-message"></span>
                </div>
                <div class="toast-close d-flex flex-column h-100">
                    <button type="button" class="btn-close btn-close-white align-items-center" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>`

            const containerTemplate = document.createElement("template");
            // set z-index really high, force it to show over the top of modal overlays
            containerTemplate.innerHTML = `<div class="toast-wrapper position-absolute" style="z-index: 10000;"></div>`

            let toastContainer;
            let removeContainerOnDismiss = false;

            const containerClone = containerTemplate.content.cloneNode(true);
            toastContainer = containerClone.querySelector('.toast-wrapper');
            document.body.appendChild(toastContainer);
            removeContainerOnDismiss = true;

            const clone = template.content.cloneNode(true);
            const toast = clone.querySelector(".toast");
            if (title !== null) {
                toast.querySelector(".toast-title").innerText = title;
            }

            toast.querySelector(".toast-message").innerText = message;

            if (style !== 'default')
                toast.classList.add(`toast-${style}`);

            const bsToast = bootstrap.Toast.getOrCreateInstance(toast);

            toast.addEventListener('hidden.bs.toast', () => {
                toast.removeEventListener('hidden.bs.toast', null);
                bsToast.dispose()

                if (removeContainerOnDismiss) {
                    toastContainer.remove();
                }
            });

            toastContainer.appendChild(toast);
            bsToast.show();

        }
    }
};

Object.defineProperty(Array.prototype,
    'first',
    {
        value() {
            return this.find(e => true) // or this.find(Boolean)
        }
    });