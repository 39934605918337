function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function isNumeric(num)
{
    // if null or undefined, return false
    if(num == null) {
        return false;
    }
    
    return !isNaN(parseFloat(num)) && isFinite(num);
}