window.navigation = {
    goToUrl: function (url) {
        location.href = url;
    }
};

// methods for managing custom browser events
window.events = {
    dispatch: function (eventName, args, target) {
        let element = window;
        if (target) {
            element = document.querySelector(target);
        }

        var customEventArgs = {};
        if (args != null) {
            customEventArgs.detail = args;
        }
        const event = new CustomEvent(eventName, customEventArgs);

        element.dispatchEvent(event);
    },
    subscribe: function (eventName, dotnetHelper, callbackFun, target, timeout) {
        let element = window;
        if (target) {
            element = document.querySelector(target);
        }

        element.addEventListener(
            eventName,
            (e) => {
                if (timeout) {
                    setTimeout(() => {
                        dotnetHelper.invokeMethodAsync(callbackFun, e.detail);
                    }, timeout);
                    return;
                }

                // todo: do we need to handle removing this listener
                dotnetHelper.invokeMethodAsync(callbackFun, e.detail);
            },
            false
        );
    }
};

// methods for managing modals
//
window.modals = {
    onInitialized: function (target, dotnetHelper) {
        const modalSelector = document.querySelector(target);

        modalSelector.addEventListener('shown.bs.modal',
            () => {
                dotnetHelper.invokeMethodAsync('onModalShown');
            });

        modalSelector.addEventListener('hidden.bs.modal',
            () => {
                dotnetHelper.invokeMethodAsync('onModalHidden');
            });
    },
    toggle: function (target, action, modalDismissButtonId) {

        return new Promise((resolve, reject) => {

            const modalSelector = document.querySelector(target);
            const modal = bootstrap.Modal.getOrCreateInstance(modalSelector)

            const handleEscapeKey = (event) => {
                if (event.key === "Escape") {
                    var element = document.activeElement;
                    if (element && element.blur) {
                        element.blur();
                    }
                    document.getElementById(modalDismissButtonId).click();
                }
            }

            if (action === 'show') {
                if (modal._isShown || modal._isTransitioning) {
                    //modal is already shown so just resolve
                    resolve();
                } else {
                    //modal isn't shown so add listener for show event
                    modalSelector.addEventListener('shown.bs.modal',
                        () => {
                            document.addEventListener("keydown", handleEscapeKey);
                            resolve();
                        }, {once: true});

                }

                modal.show();

            } else if (action === 'hide') {
                if (modal._isShown || modal._isTransitioning) {
                    //modal is shown so add listener for hide event
                    modalSelector.addEventListener('hidden.bs.modal', () => {
                        document.removeEventListener("keydown", handleEscapeKey);
                        resolve();
                    }, {once: true});


                } else {
                    //modal isn't shown so just resolve
                    resolve();
                }

                modal.hide();
            }
        });
    }
};


// methods for managing interop of the select2 js component
//
window.slimSelectComponent = {
    selects: {},
    init: function (id, allowDeselect, defaultPlaceholder, dotnetHelper, onChangeNameFunc) {
        window.slimSelectComponent.selects[id] = new SlimSelect({
            select: document.getElementById(id),
            settings: {
                openPosition: 'auto',
                allowDeselect: allowDeselect ?? false,
                placeholderText: defaultPlaceholder
            },
            events: {
                afterChange: (newValues) => {

                    if (dotnetHelper) {
                        if (newValues.length > 0) {
                            let values = newValues.map(v => v.value);
                            dotnetHelper.invokeMethodAsync(onChangeNameFunc, values);
                        } else {
                            dotnetHelper.invokeMethodAsync(onChangeNameFunc, []);
                        }
                    }
                }
            }
        });
    },
    toggleDisabled: function (id, disabled) {
        const x = window.slimSelectComponent.selects[id];
        if (disabled) {
            x.disable();
        } else {
            x.enable();
        }
    },
    setValues: function (id, values) {
        const x = window.slimSelectComponent.selects[id];
        x.setData()
        x.setData(values);
    }
}

window.datePicker = {
    pickers: {},
    init: function (id, opts, dotnetHelper, onChangeNameFunc) {
        let element = document.getElementById(id);

        if (element.nodeName !== "INPUT") {
            element = element.getElementsByTagName("input")[0];
        }

        if(!opts) {
            opts = {};
        }

        opts.onChange = (date, dateString) => {
            dotnetHelper.invokeMethodAsync(onChangeNameFunc, date);
        }

        this.pickers[id] = flatpickr(element, opts);
    },
    setValue: function (id, values) {
        const x = window.datePicker.pickers[id];
        x.setDate(values);
    },
    clear: function (id) {
        const x = window.datePicker.pickers[id];
        x.clear();
    },
    setMinMaxDates: function (id, minDateStr, maxDateStr) {
        const x = window.datePicker.pickers[id];

        let minDate = minDateStr ? Date.parse(minDateStr) : null;
        let maxDate = maxDateStr ? Date.parse(maxDateStr) : null;

        x.set('minDate', minDate);
        x.set('maxDate', maxDate);
    }
}

// methods for managing interop for the Tabs component(s)
//
window.tabs = {
    initialize: (elementId, dotNetHelper) => {
        let navTabsEl = document.getElementById(elementId);
        let triggerTabList = [].slice.call(navTabsEl.querySelectorAll('button.nav-link'));

        triggerTabList.forEach(function (tabEl) {
            tabEl?.addEventListener('show.bs.tab', (event) => {
                // event.target --> active tab
                // event.relatedTarget --> previous active tab (if available)
                dotNetHelper.invokeMethodAsync('bsShowTab', event.target?.id, event.relatedTarget?.id);
            });
            tabEl?.addEventListener('shown.bs.tab', (event) => {
                // event.target --> active tab
                // event.relatedTarget --> previous active tab
                dotNetHelper.invokeMethodAsync('bsShownTab', event.target?.id, event.relatedTarget?.id);
            });
            tabEl?.addEventListener('hide.bs.tab', (event) => {
                // event.target --> current active tab
                // event.relatedTarget --> new soon-to-be-active tab
                dotNetHelper.invokeMethodAsync('bsHideTab', event.relatedTarget?.id, event.target?.id);
            });
            tabEl?.addEventListener('hidden.bs.tab', (event) => {
                // event.target --> previous active tab
                // event.relatedTarget --> new active tab
                dotNetHelper.invokeMethodAsync('bsHiddenTab', event.relatedTarget?.id, event.target?.id);
            });
        });
    },
    show: (elementId) => {
        bootstrap?.Tab?.getOrCreateInstance(document.getElementById(elementId))?.show();
    },
    dispose: (elementId) => {
        bootstrap?.Tab?.getOrCreateInstance(document.getElementById(elementId))?.dispose();
    }
}

window.utils = {
    disableChildElements: function (rootElementSelector) {
        let rootEl = document.querySelector(rootElementSelector);
        let childNodes = rootEl.getElementsByTagName('*');
        for (let node of childNodes) {
            node.disabled = true;
        }
    }
}

window.security = {
    getToken: async function () {
        return document.cookie
            .split("; ")
            .find(row => row.startsWith("XSRF-TOKEN="))
            .split("=")[1];
    }
}

window.tooltips = {
    addTooltips: function (id) {
        if (id) {
            let el = document.getElementById(id);
            return new bootstrap.Tooltip(el)
        }
    }
}

window.menu = {
    showDropdown: (elementId) => {
        const element = document.getElementById(elementId);
        element.addEventListener('shown.bs.dropdown', () => {
            // the :focus-visible selector is applied b/c popperJs focuses programatically, which the browser thinks is keyboard focus
            // this is the suggested way to remove that focus when programatically showing the dropdown
            // https://github.com/tailwindlabs/headlessui/issues/1694#issuecomment-1273759650
            requestAnimationFrame(() => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            });
        });
        bootstrap.Dropdown.getOrCreateInstance(element).show();
    },
    hideDropdown: (elementId) => {
        bootstrap.Dropdown.getOrCreateInstance(document.getElementById(elementId)).hide();
    }
}

window.files = {
    // download a file from a stream
    // see: https://learn.microsoft.com/en-us/aspnet/core/blazor/file-downloads?view=aspnetcore-8.0#download-from-a-stream
    // used by FileDownloadService.DownloadFile
    download: async (fileName, contentStreamByReference) => {
        const arrayBuffer = await contentStreamByReference.arrayBuffer();
        const blob = new Blob([arrayBuffer]);
        const url = URL.createObjectURL(blob);
        const anchorElement = document.createElement('a');
        anchorElement.href = url;
        anchorElement.download = fileName ?? '';
        anchorElement.click();
        anchorElement.remove();
        URL.revokeObjectURL(url);
    }
}